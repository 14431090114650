<template>
  <div class="customElm">
    <img src="../../assets/other/customElm-page.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'customElm',
  data() {
    return {

    }
  },
  created() { },
  methods: {}
}
</script>

<style lang='less' scoped>
</style>